import polyglotI18nProvider from "ra-i18n-polyglot";
import chineseMessages from "ra-language-chinese";
import { resolveBrowserLocale } from "ra-core";

const messages = {
    zh: chineseMessages,
};
const i18nProvider = polyglotI18nProvider(
    (locale) => (messages[locale] ? messages[locale] : messages.zh),
    resolveBrowserLocale()
);

export default i18nProvider;
