import {
  Datagrid,
  List,
  SimpleList,
  TextField,
  DateField,
  ImageField,
  Filter,
  DateInput,
  RadioButtonGroupInput,
  NumberInput,
  TextInput,
} from "react-admin";
import { useMediaQuery, Modal } from "@material-ui/core";
import useStyles from "./PointHistoryStyle";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Pagination } from "./components";

const PointsFilter = (props) => (
  <Filter {...props}>
    <RadioButtonGroupInput
      alwaysOn
      label="获取积分方式"
      source="point_type"
      choices={[
        { id: "mannual", name: "手工获取积分" },
        { id: "wechat-auto-points", name: "微信自动积分" },
        { id: "other", name: "其他方式获取" },
      ]}
    />
    <DateInput label="开始日期" source="start_at" />
    <DateInput label="结束日期" source="end_at" />
    <NumberInput alwaysOn label="手机号搜索" source="mobile" />
    <TextInput alwaysOn label="备注搜索" source="comment" resettable />
  </Filter>
);

export const PointsHistoryList = (props) => {
  const [open, setOpen] = useState(false);
  const [img, setImg] = useState("");
  const state = useLocation();
  const pdate = state.pathname === "/ponit/histories" ? state.date : "";
  const classes = useStyles(props);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleImg = (url) => {
    setImg(url.target?.currentSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List
        {...props}
        pagination={<Pagination />}
        sort={{ field: "created_at", order: "desc" }}
        title="会员获取积分明细"
        filters={<PointsFilter />}
        filterDefaultValues={
          pdate ? { start_at: pdate || null, end_at: pdate || null } : null
        }
      >
        {isSmall ? (
          <SimpleList
            leftAvatar={(record) => (
              <div className={classes.avatar}>
                <img
                  src={record?.wechat?.data.avatarUrl}
                  alt={record?.wechat?.data.nickName}
                />
              </div>
            )}
            primaryText={(record) => <span>积分{record.amount} </span>}
            secondaryText={(record) => <span>时间 {record.created_at}</span>}
          />
        ) : (
          <div>
            <Datagrid>
              <div className={classes.avatarWrapper}>
                <ImageField
                  className={classes.avatar}
                  source="wechat.data.avatarUrl"
                  label="用户头像"
                />
                <TextField source="wechat.data.nickName" label="用户昵称" />
              </div>
              <TextField source="user.data.name" label="用户姓名" />
              <TextField source="user.data.mobile" label="手机号" />
              <TextField source="comment" label="备注" />
              <TextField source="amount" label="积分" />
              <ImageField
                onClick={(e) => handleImg(e)}
                source="image"
                label="凭证"
                className={classes.pic}
              />
              <DateField
                source="created_at"
                label="时间"
                locales="zh-CN"
                showTime
              />
            </Datagrid>
          </div>
        )}
      </List>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <img className={classes.bigPic} src={img} title="详情" alt="" />
        </div>
      </Modal>
    </>
  );
};
