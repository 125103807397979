// .avatar {
//   img {
//     width: 100px;
//     height: auto;
//     border-radius: 50%;
//   }
// }
import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  avatarWrapper: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    "& img": {
      width: "50px",
      height: "auto",
      borderRadius: "50%",
    },
  },
  pic: {
    "& img": {
      width: "80px",
      height: "auto",
    },
  },
  bigPic: {
    "& img": {
      width: "200px",
      height: "auto",
    },
  },
  modal: {
    overflowY: "auto",
    "& img": {
      textAlign: "center",
      width: 550,
      height: "auto",
      position: "absolute",
      border: "2px solid #000",
      boxShadow: 10,
      padding: 10,
    },
  },
}));
