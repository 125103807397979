import { Admin, Resource } from "react-admin";

import authProvider from "./auth";
import dataProvider from "./http";
import i18nProvider from "./i18n";
import Dashboard from "./Dashboard";
import { UsersList, UsersEdit, UsersCreate } from "./Users";
import { RolesList, RolesEdit, RolesCreate } from "./Roles";
import { CouponsList, CouponsEdit, CouponsCreate } from "./Coupons";
import {
  ParkingCouponsList,
  ParkingCouponsEdit,
  ParkingCouponsCreate,
  ParkingOrdersList,
} from "./ParkingCoupons";
import { CategoriesList, CategoriesEdit, CategoriesCreate } from "./Categories";
import { ShopsCreate, ShopsEdit, ShopsList } from "./Shops";
import { MembersList, MembersCreate } from "./Members";
import MyLayout from "./Layout";
import { AuditorsCreate, AuditorsEdit, AuditorsList } from "./Auditors";
import { FloorsCreate, FloorsEdit, FloorsList } from "./Floors";
import {
  AdvertisementsList,
  AdvertisementsEdit,
  AdvertisementsCreate,
} from "./Ads";
import {
  MemberLevelsCreate,
  MemberLevelsEdit,
  MemberLevelsList,
} from "./MemberLevels";
import {
  MemberGiftsCreate,
  MemberGiftsEdit,
  MemberGiftsList,
  MemberGiftOrdersList,
} from "./MemberGifts";
import { ActivitiesList, ActivitiesEdit, ActivitiesCreate } from "./Activities";
import { PointsHistoryList } from "./PointsHistory";
import { MemberBirthdayList } from "./MembersBirthday";
import {
  SupermarketShowList,
  SupermarketShowEdit,
  SupermarketShowCreate,
} from "./SupermarketCouponsShow";
import customRoutes from "./customRoutes";
import { ShopQrcodeList } from "./ShopQrcode";
import {
  RegisterPointsCreate,
  RegisterPointsEdit,
  RegisterPointsList,
} from "./RegisterPoints";
import { ArticleCreate, ArticleEdit, ArticleList } from "./Article";
import Login from "./Login";

const App = () => {
  console.log('hhhhh')
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      loginPage={Login}
      dashboard={Dashboard}
      layout={MyLayout}
      disableTelemetry
    >
      <Resource
        name="members"
        list={MembersList}
        create={MembersCreate}
        options={{ label: "会员" }}
      />
      <Resource
        name="member/levels"
        list={MemberLevelsList}
        create={MemberLevelsCreate}
        edit={MemberLevelsEdit}
        options={{ label: "会员等级" }}
      />
      <Resource
        name="member/gifts"
        list={MemberGiftsList}
        create={MemberGiftsCreate}
        edit={MemberGiftsEdit}
        options={{ label: "会员礼品" }}
      />
      <Resource
        name="member/gift/orders"
        list={MemberGiftOrdersList}
        options={{ label: "礼品订单" }}
      />

      <Resource
        name="shops"
        list={ShopsList}
        edit={ShopsEdit}
        create={ShopsCreate}
        options={{ label: "店铺" }}
      />
      <Resource
        name="floors"
        list={FloorsList}
        edit={FloorsEdit}
        create={FloorsCreate}
        options={{ label: "楼层" }}
      />
      <Resource
        name="shop/categories"
        list={CategoriesList}
        edit={CategoriesEdit}
        create={CategoriesCreate}
        options={{ label: "分类" }}
      />
      <Resource
        name="advertisements"
        list={AdvertisementsList}
        edit={AdvertisementsEdit}
        create={AdvertisementsCreate}
        options={{ label: "广告" }}
      />
      <Resource
        name="articles"
        list={ArticleList}
        edit={ArticleEdit}
        create={ArticleCreate}
        options={{ label: "文章" }}
      />
      <Resource
        name="supermarket-coupons"
        list={CouponsList}
        edit={CouponsEdit}
        create={CouponsCreate}
        options={{ label: "超市优惠券" }}
      />
      <Resource
        name="coupons"
        list={CouponsList}
        edit={CouponsEdit}
        create={CouponsCreate}
        options={{ label: "商铺优惠券" }}
      />
      <Resource
        name="parking/coupons"
        list={ParkingCouponsList}
        edit={ParkingCouponsEdit}
        create={ParkingCouponsCreate}
        options={{ label: "停车优惠券" }}
      />
      <Resource
        name="parking/orders"
        list={ParkingOrdersList}
        options={{ label: "停车订单" }}
      />
      <Resource
        name="admins"
        list={UsersList}
        edit={UsersEdit}
        create={UsersCreate}
        options={{ label: "管理员" }}
      />
      <Resource
        name="roles"
        list={RolesList}
        edit={RolesEdit}
        create={RolesCreate}
        options={{ label: "角色" }}
      />
      <Resource
        name="auditors"
        list={AuditorsList}
        edit={AuditorsEdit}
        create={AuditorsCreate}
        options={{ label: "核销员" }}
      />
      <Resource
        name="ponit/histories"
        list={PointsHistoryList}
        options={{ label: "会员获取积分明细" }}
      />

      <Resource
        name="member/birthdays"
        list={MemberBirthdayList}
        options={{ label: "会员今日生日" }}
      />
      <Resource
        name="point/activities"
        list={ActivitiesList}
        edit={ActivitiesEdit}
        create={ActivitiesCreate}
        options={{ label: "积分活动管理" }}
      />
      <Resource
        name="member/extra/infos"
        list={RegisterPointsList}
        edit={RegisterPointsEdit}
        create={RegisterPointsCreate}
        options={{ label: "注册积分" }}
      />
      <Resource
        name="supermarket/show"
        list={SupermarketShowList}
        edit={SupermarketShowEdit}
        create={SupermarketShowCreate}
        options={{ label: "子超市优惠券" }}
      />
      <Resource
        name="invitations"
        list={ShopQrcodeList}
        options={{ label: "店铺专属二维码统计" }}
      />
      <Resource name="permissions" />
      <Resource name="users" />
      <Resource name="settings" />
    </Admin>
  );
};

export default App;
