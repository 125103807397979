import { Form } from "react-final-form";
import { RadioButtonGroupInput, DateInput } from "react-admin";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useRef } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Legend,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { httpClient } from "./http";
import { useHistory } from "react-router-dom";

const MembersChart = () => {
  const onSubmit = useRef();
  const [chart, setChart] = useState([]);
  let myDate = new Date();
  let chartDate =
    myDate.getFullYear() + "-" + myDate.getMonth() + "-" + myDate.getDate();
  let chartDate1 =
    myDate.getFullYear() +
    "-" +
    (myDate.getMonth() + 1) +
    "-" +
    myDate.getDate();

  const FormBody = ({ handleSubmit }) => {
    return (
      <form onSubmit={handleSubmit}>
        <RadioButtonGroupInput
          defaultValue={0}
          source="type"
          label="天/周/月"
          choices={[
            { id: 0, name: "按天" },
            { id: 1, name: "按周" },
            { id: 2, name: "按月" },
          ]}
        />
        <DateInput
          defaultValue={chartDate}
          source="start_date"
          label="开始日期"
        />
        <DateInput
          defaultValue={chartDate1}
          source="end_date"
          label="结束日期"
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ margin: "20px" }}
        >
          查询
        </Button>
      </form>
    );
  };

  onSubmit.current = async (inputData) => {
    let type = inputData?.type ? inputData?.type : 0;
    let start_date = inputData?.start_date ? inputData?.start_date : chartDate;
    let end_date = inputData?.end_date ? inputData?.end_date : chartDate1;

    const res = await httpClient.get("data/member/growth", {
      params: {
        type,
        start_date,
        end_date,
      },
    });
    const list = [];
    for (let i = 0; i < res.data.length; i++) {
      list.push({
        id: i + 1,
        date: res.data[i].date,
        新增会员: parseInt(res.data[i].count),
      });
    }
    setChart(list);
  };

  const ChartList = () => {
    const history = useHistory();
    const columns = [
      { field: "id", headerName: "序号", width: 130 },
      { field: "date", headerName: "日期", width: 130 },
      { field: "新增会员", headerName: "新增会员数", width: 200 },
    ];
    const rows = chart;

    const onTableCell = (e) => {
      if (e.field === "date") {
        history.push({ pathname: "/members", date: e.formattedValue });
      }
    };

    return (
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        onCellClick={(e) => {
          onTableCell(e);
        }}
      />
    );
  };

  useEffect(() => {
    onSubmit.current();
  }, []);

  return (
    <div>
      <Form onSubmit={onSubmit.current} component={FormBody} />
      <div style={{ width: "85%", height: "90%" }}>
        <ResponsiveContainer width="85%" height="85%">
          <ComposedChart width={730} height={250} data={chart}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid stroke="#f5f5f5" />
            <Area
              type="monotone"
              dataKey="新增会员"
              fill="#8884d8"
              stroke="#8884d8"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div style={{ width: "85%", height: "65%" }}>
        <ChartList />
      </div>
    </div>
  );
};

export default MembersChart;
