import { makeStyles } from "@material-ui/core";
import {
  Avatar,
  Button,
  Carousel,
  Form,
  Modal,
  Popover,
  Segmented,
  Upload,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import { useEffect, useRef, useState } from "react";
import { httpClient } from "./http";
import { stringify } from "qs";
import { useNotify } from "react-admin";

const useStyles = makeStyles((theme) => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  typography: {
    padding: theme.spacing(2),
  },
  upload: {
    width: "20px",
    height: "20px",
  },
  bk: {
    height: "100%",
    width: "100%",
    overflowY: "hidden",
    background: "#ddd",
    display: "flex",
    padding: "30px",
    // justifyContent: "center",
    // alignItems: "center",
  },
  intro: {
    margin: "20px",
  },
  introdetail: {
    margin: "50px",
  },
  weapp: {
    marginRight: "100px",
    height: "700px",
    width: "300px",
    borderRadius: "30px",
    border: "2px solid #fff",
  },
  top: {
    width: "100%",
    height: "250px",
    left: "0",
    top: "0",
    zIndex: "-100",
    borderRadius: "10% 10% 10% 10%",
    backgroundColor: "#242323",
    backgroundSize: "100% 100%",
    // backgroundSize: "contain"
  },
  topp: {
    paddingTop: "30px",
  },
  toptitle: {
    zIndex: "100",
    paddingTop: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  page: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "-170px",
  },
  pageMessage: {
    width: "280px",
    height: "90px",
    display: "flex",
    borderRadius: "10px",
    marginBottom: "10px",
    backgroundSize: "100% 100%",
    backgroundColor: "#fff",
  },
  pagePark: {
    width: "280px",
    height: "145px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    marginBottom: "10px",
    backgroundSize: "100% 100%",
  },
  pageParkTitle: {
    width: "70px",
    margin: "10px",
    fontWeight: "bold",
  },
  pageMall: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
  },
  mallbk: {
    height: "50px",
    width: "200px",
  },
  mallPic: {
    textAlign: "center",
    margin: "5px",
    width: "80px",
    height: "80px",
    borderRadius: "10%",
    backgroundSize: "100% 100%",
    backgroundColor: "#777",
  },
  pageActive: {
    width: "280px",
    height: "150px",
    backgroundColor: "#eee",
    borderRadius: "10px",

    marginBottom: "10px",
  },
  activeImg: {
    width: "280px",
    height: "150px",
    backgroundColor: "#ddd",
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundSize: "100% 100%",
    alignItems: "center",
    fontSize: "10px",
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  tabbar: {
    bottom: "0",
    left: "0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  member: {
    display: "flex",
    padding: "0 5px 0 5px",
    justifyContent: "center",
    alignItems: "center",
    margin: "22px 5px",
    fontSize: "5px",
    color: "#000",
    fontWeight: "10px",
    height: "20px",
    borderRadius: "10px",
    backgroundColor: "#ddd",
    textAlign: "center",
  },
  gifts: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100px",

    marginBottom: "10px",
  },
  gift: {
    margin: "10px",
    width: "80px",
    height: "90px",
    backgroundSize: "100%",
    backgroundColor: "#fff",
    textAlign: "center",
    paddingTop: "10px",
    borderRadius: "10px",
  },
  fastPoints: {
    width: "50px",
    height: "80px",
    borderRadius: "10px",
    fontSize: "5px",
    textAlign: "center",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fastImg: {
    width: "40px",
    height: "50px",
  },
}));

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const WeappList = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const [tab, setTab] = useState("user1");
  const [homePageValue, setHomePageValue] = useState(null);
  const [color, setColor] = useState();
  const [topTitleColor, setTopTitleColor] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [pointBkList, setPointBkList] = useState([]);
  const [fastList, setFastList] = useState([]);
  const [pointsList, setPointsList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [iconList, setIconList] = useState([]);
  const [gifts, setGifts] = useState([]);
  const onFinish = useRef();
  const weappRef = useRef();
  const activeRef = useRef();
  const giftsRef = useRef();

  const onUpload = async (value, type) => {
    const file = await getBase64(value.file);
    let res = await httpClient.post("upload/images", stringify({ file: file }));
    let data = value.file;
    data.uploadUrl = res.data;
    data.status = "done";
    data.originFileObj = value.file;
    if (!data.url && !data.preview) {
      data.preview = file;
    }
    if (type === "fileList") {
      let newFileList = fileList;
      newFileList.push(data);
      setFileList([].concat(newFileList));
    } else if (type === "pointsList") {
      let newFileList = pointsList;
      newFileList.push(data);
      setPointsList([].concat(newFileList));
    } else if (type === "iconList") {
      let newFileList = iconList;
      newFileList.push(data);
      setIconList([].concat(newFileList));
    } else if (type === "fastList") {
      let newFileList = fastList;
      newFileList.push(data);
      setFastList([].concat(newFileList));
    } else if (type === "messageList") {
      let newFileList = messageList;
      newFileList.push(data);
      setMessageList([].concat(newFileList));
    } else if (type === "pointBkList") {
      let newFileList = pointBkList;
      newFileList.push(data);
      setPointBkList([].concat(newFileList));
    }
  };

  const choose = [
    {
      title: "停车",
      img: homePageValue?.pointsUrl[0],
    },
    {
      title: "超市",
      img: homePageValue?.pointsUrl[2],
    },
    { title: "商铺", img: homePageValue?.pointsUrl[4] },
  ];

  const onChangeColor = (colorObj) => {
    setColor(colorObj.hex);
  };

  const onChangeTopTitleColor = (colorObj) => {
    setTopTitleColor(colorObj.hex);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onfileRemove = (value, type) => {
    if (type === "fileList") {
      let newList = fileList;
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].name === value.name) {
          newList.splice(i, 1);
        }
      }
      setFileList([].concat(newList));
    } else if (type === "pointsList") {
      let newList = pointsList;
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].name === value.name) {
          newList.splice(i, 1);
        }
      }
      setPointsList([].concat(newList));
    } else if (type === "iconList") {
      let newList = iconList;
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].name === value.name) {
          newList.splice(i, 1);
        }
      }

      setIconList([].concat(newList));
    } else if (type === "fastList") {
      let newList = fastList;
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].name === value.name) {
          newList.splice(i, 1);
        }
      }
      setFastList([].concat(newList));
    } else if (type === "messageList") {
      let newList = messageList;
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].name === value.name) {
          newList.splice(i, 1);
        }
      }
      setMessageList([].concat(newList));
    } else if (type === "pointBkList") {
      let newList = pointBkList;
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].name === value.name) {
          newList.splice(i, 1);
        }
      }
      setPointBkList([].concat(newList));
    }
  };

  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 40,
    },
  };

  const onTabChange = (value) => {
    setTab(value);
  };

  onFinish.current = async () => {
    let fileUrl = fileList[0]?.uploadUrl || homePageValue?.fileUrl;
    let messageUrl = messageList[0]?.uploadUrl || homePageValue?.messageUrl;
    let fastUrl = homePageValue?.fastUrl;
    let pointBkUrl = homePageValue?.pointBkUrl;
    let pointsUrl = homePageValue?.pointsUrl;
    let iconUrl = homePageValue?.iconUrl;
    if (fastList.length > 0) {
      fastUrl = [];
      for (let i = 0; i < fastList.length; i++) {
        fastUrl.push(fastList[i].uploadUrl);
      }
    }
    if (pointBkList.length > 0) {
      pointBkUrl = [];
      for (let i = 0; i < pointBkList.length; i++) {
        pointBkUrl.push(pointBkList[i].uploadUrl);
      }
    }
    if (pointsList.length > 0) {
      pointsUrl = [];
      for (let i = 0; i < pointsList.length; i++) {
        pointsUrl.push(pointsList[i].uploadUrl);
      }
    }
    if (iconList.length > 0) {
      iconUrl = [];
      for (let i = 0; i < iconList.length; i++) {
        iconUrl.push(iconList[i].uploadUrl);
      }
    }

    const data = JSON.stringify({
      color,
      topTitleColor,
      fileUrl,
      messageUrl,
      pointBkUrl,
      fastUrl,
      pointsUrl,
      iconUrl,
    });

    const res = await httpClient.post(
      "settings",
      stringify({
        key: "homepage",
        value: data,
      })
    );
    if (res.status === 200) {
      notify("提交成功！", "info");
    } else {
      notify("提交失败！", "error");
    }

    return;
  };

  weappRef.current = async () => {
    const res = await httpClient.get("setting/homepage");
    if (res.code === 400 && !res.data?.key) {
      return;
    }
    let value = JSON.parse(res.data.data.value);
    setHomePageValue(value);
    setColor(value?.color);
    setTopTitleColor(value?.topTitleColor);
  };

  activeRef.current = async () => {
    const res = await httpClient.get("advertisements");
    const rr = res?.data?.data;
    let adList = [];
    for (let i in rr) {
      if (rr[i].type === 3) {
        adList.push(rr[i]);
      }
    }
    setActiveList(adList);
  };

  giftsRef.current = async () => {
    const res = await httpClient.get("member/gifts");
    const data = res.data.data.slice(Math.random(res.data.data.length - 1), 3);
    setGifts(data);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: "1px",
        }}
      >
        上传
      </div>
    </div>
  );

  useEffect(() => {
    weappRef.current();
    activeRef.current();
    giftsRef.current();
  }, []);

  return (
    <div className={classes.ugTitle}>
      <div className={classes.bk}>
        <div className={classes.weapp}>
          <div
            className={classes.top}
            style={{
              backgroundImage: `url(${
                fileList[0]?.url ||
                fileList[0]?.preview ||
                homePageValue?.fileUrl
              })`,
            }}
          >
            <div className={classes.topp}></div>
            <div
              className={classes.toptitle}
              style={{
                color: topTitleColor || homePageValue?.topTitleColor,
              }}
            >
              瑞达丝路新天地
            </div>
          </div>
          <div className={classes.page}>
            <div
              className={classes.pageMessage}
              style={{
                backgroundImage: `url(${
                  messageList[0]?.url ||
                  messageList[0]?.preview ||
                  homePageValue?.messageUrl
                })`,
              }}
            >
              <div style={{ display: "flex", flex: "1" }}>
                <Avatar style={{ margin: "5px" }} size={55}>
                  x
                </Avatar>
                <div
                  style={{
                    height: "55px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "5px",
                  }}
                >
                  昵称
                </div>
                <div className={classes.member}>普通会员</div>
              </div>
              <div
                className={classes.fastPoints}
                style={{ backgroundColor: color }}
              >
                <div>
                  <img
                    className={classes.fastPoints}
                    alt=""
                    src={
                      fastList[0]?.url ||
                      fastList[0]?.preview ||
                      homePageValue?.fastUrl[0]
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className={classes.pagePark}
              style={{
                backgroundImage: `url(${
                  pointBkList.length > 0
                    ? pointBkList[0]?.url || pointBkList[0]?.preview
                    : homePageValue?.pointBkUrl
                    ? homePageValue?.pointBkUrl[0]
                    : null
                })`,
              }}
            >
              <div style={{ display: "flex" }}>
                <div className={classes.mallbk}></div>
              </div>
              <div className={classes.pageMall}>
                {pointsList.length > 0
                  ? pointsList.map((item, index) => {
                      if (index % 2 !== 0) {
                        return null;
                      }
                      return (
                        <div
                          key={index}
                          className={classes.mallPic}
                          style={{
                            backgroundImage: `url(${
                              item?.url || item?.preview
                            })`,
                          }}
                        ></div>
                      );
                    })
                  : choose.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classes.mallPic}
                          style={{
                            backgroundImage: `url(${item?.img})`,
                          }}
                        ></div>
                      );
                    })}
              </div>
            </div>
            <div className={classes.pageActive}>
              <Carousel className={classes.pageActive} autoplay dots>
                {activeList.map((item, index) => {
                  return (
                    <div key={index} className={classes.activeImg}>
                      <img
                        className={classes.activeImg}
                        alt=""
                        src={item.image.zh}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className={classes.gifts}>
              {gifts.map((item, index) => {
                return (
                  <div className={classes.gift} key={index}>
                    <img
                      style={{ width: "70px", height: "70px" }}
                      alt=""
                      src={item.image.zh}
                    />
                    <div
                      style={{
                        fontSize: "10px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.name.zh}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.tabbar}>
            <Segmented
              onChange={onTabChange}
              options={[
                {
                  label: (
                    <div
                      style={{
                        padding: 9,
                      }}
                    >
                      <Avatar
                        shape="square"
                        src={
                          tab === "user1"
                            ? iconList[0]?.url ||
                              iconList[0]?.preview ||
                              homePageValue?.iconUrl[0]
                            : iconList[1]?.url ||
                              iconList[1]?.preview ||
                              homePageValue?.iconUrl[1]
                        }
                      />
                      <div>首页</div>
                    </div>
                  ),
                  value: "user1",
                },
                {
                  label: (
                    <div
                      style={{
                        padding: 9,
                      }}
                    >
                      <Avatar
                        shape="square"
                        src={
                          tab === "user2"
                            ? iconList[2]?.url ||
                              iconList[2]?.preview ||
                              homePageValue?.iconUrl[2]
                            : iconList[3]?.url ||
                              iconList[3]?.preview ||
                              homePageValue?.iconUrl[3]
                        }
                      />
                      <div>积分</div>
                    </div>
                  ),
                  value: "user2",
                },
                {
                  label: (
                    <div
                      style={{
                        padding: 9,
                      }}
                    >
                      <Avatar
                        shape="square"
                        src={
                          tab === "user3"
                            ? iconList[4]?.url ||
                              iconList[4]?.preview ||
                              homePageValue?.iconUrl[4]
                            : iconList[5]?.url ||
                              iconList[5]?.preview ||
                              homePageValue?.iconUrl[5]
                        }
                      />
                      <div>会员</div>
                    </div>
                  ),
                  value: "user3",
                },
                {
                  label: (
                    <div
                      style={{
                        padding: 9,
                      }}
                    >
                      <Avatar
                        shape="square"
                        src={
                          tab === "user4"
                            ? iconList[6]?.url ||
                              iconList[6]?.preview ||
                              homePageValue?.iconUrl[6]
                            : iconList[7]?.url ||
                              iconList[7]?.preview ||
                              homePageValue?.iconUrl[7]
                        }
                      />
                      <div>品牌</div>
                    </div>
                  ),
                  value: "user4",
                },
              ]}
            />
          </div>
        </div>
        <div
          style={{
            background: "#fff",
            padding: "20px",
            width: "600px",
            height: "auto",
          }}
        >
          <Form
            name="weapp"
            labelAlign="left"
            {...formItemLayout}
            onFinish={onFinish.current}
            initialValues={{}}
          >
            <Form.Item label="主题颜色" name="theme">
              <div>
                <Popover
                  content={
                    <SketchPicker color={color} onChange={onChangeColor} />
                  }
                  title="Title"
                  trigger="click"
                >
                  <Button
                    style={{
                      margin: "10px",
                      width: "100px",
                      height: "30px",
                      background: color,
                    }}
                  ></Button>
                </Popover>
              </div>
            </Form.Item>

            <Form.Item label="顶部字体颜色" name="topTitleColor">
              <div>
                <Popover
                  content={
                    <SketchPicker
                      color={topTitleColor}
                      onChange={onChangeTopTitleColor}
                    />
                  }
                  title="Title"
                  trigger="click"
                >
                  <Button
                    style={{
                      margin: "10px",
                      width: "100px",
                      height: "30px",
                      background: topTitleColor,
                    }}
                  ></Button>
                </Popover>
              </div>
            </Form.Item>

            <Form.Item label="顶部背景图片(1张)" name="topbk">
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onRemove={(value) => onfileRemove(value, "fileList")}
                customRequest={(value) => onUpload(value, "fileList")}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>

            <Form.Item label="会员信息背景图片(1张)" name="messagebk">
              <Upload
                listType="picture-card"
                fileList={messageList}
                onPreview={handlePreview}
                onRemove={(value) => onfileRemove(value, "messageList")}
                customRequest={(value) => onUpload(value, "messageList")}
              >
                {messageList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>

            <Form.Item label="快速积分图片(2张)" name="fastbk">
              <Upload
                listType="picture-card"
                fileList={fastList}
                onPreview={handlePreview}
                onRemove={(value) => onfileRemove(value, "fastList")}
                customRequest={(value) => onUpload(value, "fastList")}
              >
                {fastList.length >= 2 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>

            <Form.Item label="积分背景图片(2张)" name="pointsbk">
              <Upload
                listType="picture-card"
                fileList={pointBkList}
                onPreview={handlePreview}
                onRemove={(value) => onfileRemove(value, "pointBkList")}
                customRequest={(value) => onUpload(value, "pointBkList")}
              >
                {pointBkList.length >= 2 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
            <Form.Item label="积分兑换图标(6张 按顺序上传)" name="pointsbk">
              <Upload
                listType="picture-card"
                fileList={pointsList}
                onPreview={handlePreview}
                onRemove={(value) => onfileRemove(value, "pointsList")}
                customRequest={(value) => onUpload(value, "pointsList")}
              >
                {pointsList.length >= 6 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>

            <Form.Item label="底部导航栏图标(8张 按顺序上传)" name="tabbar">
              <Upload
                listType="picture-card"
                fileList={iconList}
                onPreview={handlePreview}
                onRemove={(value) => onfileRemove(value, "iconList")}
                customRequest={(value) => onUpload(value, "iconList")}
              >
                {iconList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>

            <Form.Item className={classes.tabbar}>
              <Button
                style={{ width: "150px", height: "40px" }}
                type="primary"
                htmlType="submit"
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
