import { Route } from "react-router-dom";
import MemberRights from "./MemberRights";
import MembersChart from "./MembersChart";
import MembersPoints from "./MembersPoints";
import MembersUsage from "./MembersUsage";
import MembersLevels from "./MembersLevels";
import Dashboard from "./Dashboard";
import { PointsUseList } from "./PointsHistoryUse";
import { MemberBirthdayWeekList } from "./MemberBirthdayWeek";
import { ReportList } from "./Report";
import { WeappList } from "./Weapp";
import { ExportReportList } from "./ExportReport";

const arr = [
  <Route exact path="/weapp" component={WeappList} />,
  <Route exact path="/birthday/week" component={MemberBirthdayWeekList} />,
  <Route exact path="/export" component={ExportReportList} />,
  <Route exact path="/ponit/use" component={PointsUseList} />,
  <Route exact path="/report" component={ReportList} />,
  <Route exact path="/member/rights" component={MemberRights} />,
  <Route exact path="/data/member/growth" component={MembersChart} />,
  <Route exact path="/data/member/point/increment" component={MembersPoints} />,
  <Route exact path="/data/member/point/usage" component={MembersUsage} />,
  <Route exact path="/data/member/levels" component={MembersLevels} />,
  <Route exact path="/data/member/info" component={Dashboard} />,
];

export default arr;
