import axios from "axios";
import qs from "qs";

const authProvider = {
  login: ({ username, password }) => {
    const options = {
      url:
        process.env.NODE_ENV === "production"
          ? "https://api.mms.bulutbazar.com/v1/clients/web/login"
          : "/v1/clients/web/login",
      method: "POST",
      data: qs.stringify({ mobile: username, password }),
      headers: {
        accept: "application/json",
        "content-type": "application/x-www-form-urlencoded",
      },
    };
    return axios(options)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        } else {
          const { data } = response;
          localStorage.setItem("auth", JSON.stringify(data));
        }
      })
      .catch((error) => {
        const { response } = error;
        if (response.status === 422) {
          const { data } = response;
          throw new Error(data.message);
        } else {
          throw new Error("Network error");
        }
      });
  },
  checkError: (error) => {
    if (error.message.includes("401")) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>{
    console.log('haha');
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject()
  },
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, fullName, avatar } = JSON.parse(localStorage.getItem("auth"));
      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
