import { Form } from "react-final-form";
import { RadioButtonGroupInput, DateInput } from "react-admin";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useRef } from "react";
import { ResponsiveContainer, PieChart, Pie, Legend, Cell } from "recharts";
import { httpClient } from "./http";

const MembersLevels = () => {
  const onSubmit = useRef();
  const [chart, setChart] = useState([]);
  let myDate = new Date();
  let chartDate =
    myDate.getFullYear() + "-" + myDate.getMonth() + "-" + myDate.getDate();
  let chartDate1 =
    myDate.getFullYear() +
    "-" +
    (myDate.getMonth() + 1) +
    "-" +
    myDate.getDate();

  const FormBody = ({ handleSubmit }) => {
    return (
      <form onSubmit={handleSubmit}>
        <RadioButtonGroupInput
          defaultValue={0}
          source="type"
          label="天/周/月"
          choices={[
            { id: 0, name: "按天" },
            { id: 1, name: "按周" },
            { id: 2, name: "按月" },
          ]}
        />
        <DateInput
          defaultValue={chartDate}
          source="start_date"
          label="开始日期"
        />
        <DateInput
          defaultValue={chartDate1}
          source="end_date"
          label="结束日期"
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ margin: "20px" }}
        >
          查询
        </Button>
      </form>
    );
  };

  onSubmit.current = async (inputData) => {
    let type = inputData?.type ? inputData?.type : 0;
    let start_date = inputData?.start_date ? inputData?.start_date : chartDate;
    let end_date = inputData?.end_date ? inputData?.end_date : chartDate1;

    const res = await httpClient.get("data/member/levels", {
      params: {
        type,
        start_date,
        end_date,
      },
    });
    const list = [];
    for (let i = 0; i < res.data.length; i++) {
      list.push({
        id: i + 1,
        count: parseInt(res.data[i].count),
        会员等级: res.data[i].level?.name.zh,
      });
    }
    setChart(list);
  };

  const ChartList = () => {
    const columns = [
      { field: "id", headerName: "序号", width: 130 },
      { field: "会员等级", headerName: "会员等级", width: 200 },
      { field: "count", headerName: "新增会员数", width: 200 },
    ];
    const rows = chart;

    return <DataGrid rows={rows} columns={columns} pageSize={5} />;
  };

  useEffect(() => {
    onSubmit.current();
  }, []);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div>
      <Form onSubmit={onSubmit.current} component={FormBody} />
      <div style={{ width: "90%", height: "90%" }}>
        <ResponsiveContainer width="85%" height="85%">
          <PieChart width={400} height={400}>
            <Pie
              data={chart}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={200}
              fill="#8884d8"
              nameKey="会员等级"
              dataKey="count"
            >
              {chart.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div style={{ width: "85%", height: "65%" }}>
        <ChartList />
      </div>
    </div>
  );
};

export default MembersLevels;
