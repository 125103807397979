import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  Filter,
  List,
  NumberField,
  NumberInput,
  RadioButtonGroupInput,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useRecordContext,
  BulkExportButton,
} from "react-admin";
import { makeStyles, useMediaQuery, Drawer, Button } from "@material-ui/core";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import PointEdit from "./PointEdit";
import {
  CreateToolbar,
  FullNameField,
  Pagination,
  TopBarActions,
  UsersGender,
} from "./components";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  drawerPaper: {
    zIndex: 100,
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  simpleRoot: { width: "100vw" },
  filter: {
    margin: "2vh",
  },
}));

const MemberFilter = (props) => {
  return (
    <Filter {...props}>
      <div label="搜索" source="mobile" alwaysOn>
        <TextInput label="搜索" source="mobile" alwaysOn resettable />
      </div>

      <div label="注册日期" source="register">
        <DateInput label="开始日期" source="register.start_at" />
        <DateInput label="结束日期" source="register.end_at" />
      </div>
      <div label="生日日期" source="birthday">
        <DateInput label="生日开始" source="birthday.birthday_start" />
        <DateInput label="生日结束" source="birthday.birthday_end" />
      </div>
      <div label="积分" source="points">
        <TextInput label="积分开始" source="points.points_start" />
        <TextInput label="积分结束" source="points.points_end" />
      </div>
    </Filter>
  );
};

const CustomEdit = () => {
  const history = useHistory();
  const record = useRecordContext();
  return (
    <Button
      style={{ width: 20, fontSize: 10 }}
      onClick={() =>
        history.push({
          pathname: `/members/${record.id}`,
          state: { isUpdate: true },
        })
      }
      variant="contained"
      color="primary"
      disableElevation
    >
      会员信息
    </Button>
  );
};

const PointsEdit = () => {
  const history = useHistory();
  const record = useRecordContext();
  return (
    <Button
      style={{ width: 20, fontSize: 10 }}
      onClick={() =>
        history.push({
          pathname: `/members/${record.id}`,
          state: { isUpdate: false },
        })
      }
      variant="contained"
      color="primary"
      disableElevation
    >
      清除积分
    </Button>
  );
};

export const MembersList = (props) => {
  const state = useLocation();
  const pdate = state.pathname === "/members" ? state.date : "";
  const classes = useStyles();
  const history = useHistory();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const handleClose = useCallback(() => {
    history.push("/members");
  }, [history]);

  const PostBulkActionButtons = (props) => {
    return (
      <Fragment>
        <BulkExportButton {...props} />
      </Fragment>
    );
  };

  return (
    <Route path="/members/:id">
      {({ match }) => {
        const isMatch = !!(
          match &&
          match.params &&
          match.params.id !== "create"
        );
        return (
          <>
            <List
              {...props}
              pagination={<Pagination />}
              bulkActionButtons={<PostBulkActionButtons />}
              className={classes.list}
              filters={<MemberFilter />}
              title="会员管理"
              filterDefaultValues={
                pdate ? { start_at: pdate, end_at: pdate } : null
              }
            >
              {isSmall ? (
                <SimpleList
                  className={classes.simpleRoot}
                  primaryText={(record) => "姓名"}
                  secondaryText={(record) => record.name}
                  tertiaryText={(record) => `积分 ${record.points}`}
                  linkType={(record) => (record.canEdit ? "edit" : "show")}
                />
              ) : (
                <Datagrid optimized>
                  <FullNameField />
                  <TextField source="name" label="姓名" />
                  <DateField source="birthday" label="生日" />
                  <UsersGender source="gender" label="性别" />
                  <BooleanField
                    source="is_wechat_points_enabled"
                    label="商户圈积分授权"
                  />
                  <TextField source="mobile" label="手机号" />
                  <TextField source="license_plate_number" label="车牌号" />
                  <TextField source="last_spent_time" label="最后消费时间" />
                  <TextField source="register_from" label="注册来源" />
                  <NumberField source="points" label="积分" />
                  <EditButton label="补录积分" />
                  <PointsEdit />
                  <CustomEdit />
                </Datagrid>
              )}
            </List>
            <Drawer
              variant="persistent"
              open={isMatch}
              anchor="right"
              onClose={handleClose}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {isMatch ? (
                <PointEdit
                  id={match?.params.id}
                  onCancel={handleClose}
                  resource={props.resource}
                  basePath={props.basePath}
                />
              ) : null}
            </Drawer>
          </>
        );
      }}
    </Route>
  );
};

export const MembersCreate = (props) => {
  return (
    <Create {...props} title="新建会员" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name" label="姓名" required />
        <DateInput source="birthday" label="生日" required />
        <RadioButtonGroupInput
          label="性别"
          source="gender"
          choices={[
            { id: "1", name: "男" },
            { id: "2", name: "女" },
          ]}
          required
        />
        <NumberInput source="mobile" label="手机号" required />
        <NumberInput source="points" label="积分" required />
      </SimpleForm>
    </Create>
  );
};
