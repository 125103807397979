import {
  makeStyles,
  useMediaQuery,
  Drawer,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import { stringify } from "qs";
import {
  DateField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  NumberField,
  SimpleList,
} from "ra-ui-materialui";
import { useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  Create,
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  useRecordContext,
  BooleanField,
  useNotify,
} from "react-admin";
import { CreateToolbar, TopBarActions } from "./components";
import { currencyProps } from "./constants";
import { useHistory } from "react-router-dom";
import { httpClient } from "./http";
// import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
  drawerPaper: {
    zIndex: 100,
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  simpleRoot: { width: "100vw" },
  filter: {
    margin: "2vh",
  },
}));

export const CouponsList = (props) => {
  const [isMatch, setIsmatch] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const notify = useNotify();
  const handleClose = () => {
    setIsmatch(false);
  };

  const ShowEdit = () => {
    const history = useHistory();
    const record = useRecordContext();
    return (
      <Button
        // startIcon={<VisibilityIcon />}
        style={{ width: 50, fontSize: 10 }}
        onClick={() => {
          localStorage.setItem("supermarketId", record.id);
          history.push({
            pathname: "/supermarket/show",
            state: { id: record.id },
          });
        }}
        variant="contained"
        color="primary"
        disableElevation
      >
        查看
      </Button>
    );
  };

  const CouEdit = () => {
    const record = useRecordContext();

    return (
      <Button
        style={{ width: 20, fontSize: 10 }}
        onClick={() => {
          setIsmatch(true);
          setCouponId(record.id);
        }}
        variant="contained"
        color="primary"
        disableElevation
      >
        发放优惠券
      </Button>
    );
  };

  const filterTouser = (searchText) => ({ mobile: searchText });

  const mobileMatch = (filter, choice) =>
    choice &&
    typeof choice.mobile === "string" &&
    choice.mobile.includes(`${filter}`);

  const optionText = (record) => `${record?.name} ${record?.mobile}`;

  const onCancel = () => {
    setIsmatch(false);
  };

  const inputNumber = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
  };

  const CouponsToolbar = ({ ...props }) => {
    const handleSave = useRef();

    handleSave.current = async (values, redirect) => {
      const { user_id, quantity } = values;
      const res = await httpClient.post(
        `supermarket/coupons/${couponId}/send`,
        stringify({
          user_id,
          quantity,
        })
      );
      if (res.code === 400) {
        notify(res.data, "error");
      }
      setIsmatch(false);
    };

    return (
      <Toolbar {...props}>
        <SaveButton
          label="保存"
          onSave={handleSave.current}
          invalid={props.invalid}
        />
      </Toolbar>
    );
  };

  return (
    <>
      <List
        {...props}
        title={props.resource === "coupons" ? "商场优惠券" : "超市优惠券"}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) =>
              `满${record.bar_price / 100}减${record.face_price / 100}元`
            }
            secondaryText={(record) =>
              `补贴${record.company_allowance / 100}元`
            }
            tertiaryText={(record) => `${record.exchange_points}积分可兑换`}
          />
        ) : (
          <Datagrid>
            <NumberField source="bar_price" label="满多少" {...currencyProps} />
            <NumberField
              source="face_price"
              label="优惠金额"
              {...currencyProps}
            />
            <NumberField
              source="company_allowance"
              label="补贴金额"
              {...currencyProps}
            />
            <NumberField source="exchange_points" label="兑换积分" />
            <BooleanField source="could_user_exchange" label="可否兑换" />
            <DateField source="start_at" label="开始日期" />
            <DateField source="end_at" label="结束日期" />
            <NumberField source="valid_time" label="有效时间（小时）" />
            <NumberField source="limit_per_user" label="每人限领（张）" />
            {props.resource === "coupons" ? null : <CouEdit />}
            {props.resource === "coupons" ? null : <ShowEdit />}
            <EditButton />
            <DeleteButton confirmTitle="" mutationMode="pessimistic" />
          </Datagrid>
        )}
      </List>
      <Drawer
        variant="persistent"
        open={isMatch}
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.root}>
          <div className={classes.title}>
            <Typography variant="h6">发放优惠券</Typography>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </div>
          <SimpleForm
            className={classes.form}
            toolbar={<CouponsToolbar recordId={props.id} />}
          >
            <ReferenceInput
              reference="users"
              source="user_id"
              label="客户姓名"
              filterToQuery={filterTouser}
              isRequired
            >
              <AutocompleteInput
                optionText={optionText}
                optionValue="id"
                matchSuggestion={mobileMatch}
              />
            </ReferenceInput>
            <NumberInput
              min="0"
              onInput={inputNumber}
              source="quantity"
              label="数量"
              isRequired
            />
          </SimpleForm>
        </div>
      </Drawer>
    </>
  );
};

const CouponsTitle = ({ record }) => {
  let title = "优惠券";
  if (record && record.id) {
    title = `"满${record.bar_price}减${record.face_price}"`;
  }
  return <span>{title}</span>;
};

export const CouponsEdit = (props) => {
  return (
    <Edit {...props} title={<CouponsTitle />} actions={<TopBarActions />}>
      <SimpleForm>
        {props.resource === "coupons" && (
          <ReferenceInput source="shop_id" reference="shops" label="店铺">
            <SelectInput optionText="name.zh" optionValue="id" />
          </ReferenceInput>
        )}
        <SelectInput
          source="could_user_exchange"
          label="可否兑换"
          choices={[
            { id: 1, name: "是" },
            { id: 0, name: "否" },
          ]}
        />
        <DateTimeInput source="start_at" label="开始日期" required />
        <DateTimeInput source="end_at" label="结束日期" required />
        <NumberInput source="bar_price" label="满多少" required />
        <NumberInput source="face_price" label="优惠金额" required />
        <NumberInput source="company_allowance" label="补贴金额" />
        <NumberInput source="exchange_points" label="兑换积分" required />
        <NumberInput source="valid_time" label="有效时间（小时）" />
        <NumberInput source="limit_per_user" label="每人限领（张）" />
      </SimpleForm>
    </Edit>
  );
};

export const CouponsCreate = (props) => {
  return (
    <Create {...props} title={<CouponsTitle />} actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        {props.resource === "coupons" && (
          <ReferenceInput source="shop_id" reference="shops" label="店铺">
            <SelectInput optionText="name.zh" optionValue="id" />
          </ReferenceInput>
        )}
        <SelectInput
          source="could_user_exchange"
          label="可否兑换"
          choices={[
            { id: 1, name: "是" },
            { id: 0, name: "否" },
          ]}
        />
        <DateTimeInput source="start_at" label="开始日期" required />
        <DateTimeInput source="end_at" label="结束日期" required />
        <NumberInput source="bar_price" label="满多少" required />
        <NumberInput source="face_price" label="优惠金额" required />
        <NumberInput source="company_allowance" label="补贴金额" />
        <NumberInput source="exchange_points" label="兑换积分" required />
        <NumberInput source="valid_time" label="有效时间（小时）" />
        <NumberInput source="limit_per_user" label="每人限领（张）" />
      </SimpleForm>
    </Create>
  );
};
