import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberField,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { CreateToolbar, Pagination, TopBarActions } from "./components";

export const ParkingCouponsList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List {...props} title="停车优惠券">
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `所需积分 ${record.points}分`}
          secondaryText={(record) => `可抵${record.hours}小时`}
        />
      ) : (
        <Datagrid>
          <TextField source="points" label="所需积分" />
          <TextField source="hours" label="可抵（单位：小时）" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const OrderFilter = (props) => (
  <Filter {...props}>
    <TextInput label="搜索" source="plate_no" alwaysOn />
    <DateInput alwaysOn label="开始日期" source="start_at" />
    <DateInput alwaysOn label="结束日期" source="end_at" />
  </Filter>
);

export const ParkingOrdersList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      title="停车订单"
      filters={<OrderFilter />}
      pagination={<Pagination />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.plate_no}
          secondaryText={(record) =>
            `${Math.floor(record.elapsed_time / 60)}小时${
              record.elapsed_time % 60
            }分钟`
          }
          tertiaryText={(record) => `积分${record.exchanged_points}`}
        />
      ) : (
        <Datagrid>
          <TextField source="plate_no" label="车牌" />
          <DateField
            source="entry_time"
            label="进入时间"
            locales="zh-CN"
            showTime
          />
          <FunctionField
            label="停车时长"
            render={(record) =>
              `${Math.floor(record.elapsed_time / 60)}小时${
                record.elapsed_time % 60
              }分钟`
            }
          />
          <DateField
            source="pay_time"
            label="支付时间"
            locales="zh-CN"
            showTime
          />
          <NumberField source="exchanged_points" label="消耗积分" />
        </Datagrid>
      )}
    </List>
  );
};

export const ParkingCouponsEdit = (props) => {
  return (
    <Edit {...props} title="停车优惠券" actions={<TopBarActions />}>
      <SimpleForm>
        <TextInput source="points" label="所需积分" />
        <TextInput source="hours" label="可抵（单位：小时）" />
      </SimpleForm>
    </Edit>
  );
};

export const ParkingCouponsCreate = (props) => {
  return (
    <Create {...props} title="停车优惠券">
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="points" label="所需积分" />
        <TextInput source="hours" label="可抵（单位：小时）" />
      </SimpleForm>
    </Create>
  );
};
