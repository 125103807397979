import {
  SimpleForm,
  NumberInput,
  TextInput,
  useCreate,
  SaveButton,
  Toolbar,
  useRedirect,
  useNotify,
  Edit,
  useUpdate,
  SelectInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  AutocompleteInput,
  //   ReferenceInput,
  //   AutocompleteInput,
} from "react-admin";
import { IconButton, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 500,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

const PointUpdateToolbar = ({ recordId, ...props }) => {
  const [updatePoint] = useCreate(`member/${recordId}/mannually/points`);
  const [updateRecord] = useUpdate();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const handleSave = useRef();

  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file.rawFile);
    });

  handleSave.current = async (values, redirect) => {
    const { amount, comment, imagee } = values;
    if (!imagee) {
      return notify("请上传凭证！", { type: "error" });
    }
    const image = await convertFileToBase64(imagee);
    updatePoint(
      {
        payload: { data: { amount, comment, image } },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          notify("ra.notification.created", "info", {
            smart_count: 1,
          });
          updateRecord(
            "members",
            newRecord.id,
            { points: newRecord.points },
            newRecord
          );
          redirectTo(redirect, props.basePath, newRecord.id, newRecord);
        },
      }
    );
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        label="保存"
        onSave={handleSave.current}
        invalid={props.invalid}
      />
    </Toolbar>
  );
};

const PointDelToolbar = ({ recordId, ...props }) => {
  const [updatePoint] = useCreate(`members/${recordId}/clear/points`);
  const [updateRecord] = useUpdate();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const handleSave = useRef();

  handleSave.current = (values, redirect) => {
    const { amount, comment } = values;
    updatePoint(
      {
        payload: { data: { amount, comment } },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          notify("ra.notification.created", "info", {
            smart_count: 1,
          });
          updateRecord(
            "members",
            newRecord.id,
            { points: newRecord.points },
            newRecord
          );
          redirectTo(redirect, props.basePath, newRecord.id, newRecord);
        },
      }
    );
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        label="保存"
        onSave={handleSave.current}
        invalid={props.invalid}
      />
    </Toolbar>
  );
};

const genderTypes = [
  { id: 0, name: "未指定" },
  { id: 1, name: "男" },
  { id: 2, name: "女" },
];

const filterToQuery = (searchText) => ({ name: searchText });

const nameMatch = (filter, choice) =>
  choice.name && choice.name.zh && choice.name.zh.includes(`${filter}`);

const inputNumber = (e) => {
  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
};

const PointEdit = ({ onCancel, ...props }) => {
  const classes = useStyles();
  const { state } = useLocation();
  if (!props.id) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {state?.isUpdate === true
            ? "会员信息 "
            : state?.isUpdate === false
            ? "清除积分"
            : "补录积分"}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      {state?.isUpdate === true ? (
        <Edit {...props} title="编辑会员">
          <SimpleForm className={classes.form}>
            <TextInput source="name" label="名称" />
            <TextInput source="birthday" label="生日" />
            <SelectInput
              source="gender"
              optionText="name"
              optionValue="id"
              label="性别"
              choices={genderTypes}
            />
          </SimpleForm>
        </Edit>
      ) : state?.isUpdate === false ? (
        <Edit {...props} title="清除积分">
          <SimpleForm
            className={classes.form}
            toolbar={<PointDelToolbar recordId={props.id} />}
          >
            <NumberInput
              min="0"
              onInput={inputNumber}
              source="amount"
              label="清除积分"
              isRequired
            />
            <TextInput
              source="comment"
              label="备注"
              maxRows={15}
              multiline
              fullWidth
              isRequired
            />
          </SimpleForm>
        </Edit>
      ) : (
        <Edit {...props} title="补录积分">
          <SimpleForm
            className={classes.form}
            toolbar={<PointUpdateToolbar recordId={props.id} />}
          >
            <ReferenceInput
              reference="shops"
              source="shop_id"
              label="店铺"
              filterToQuery={filterToQuery}
              isRequired
            >
              <AutocompleteInput
                optionText="name.zh"
                optionValue="id"
                matchSuggestion={nameMatch}
              />
            </ReferenceInput>
            <NumberInput
              min="0"
              onInput={inputNumber}
              source="amount"
              label="补积分"
              isRequired
            />
            <TextInput
              source="comment"
              label="备注"
              rowsMax={15}
              multiline
              fullWidth
              isRequired
            />
            <ImageInput
              source="imagee"
              label="凭证"
              accept="image/*"
              isRequired
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleForm>
        </Edit>
      )}
    </div>
  );
};

export default PointEdit;
