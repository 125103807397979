import {
  Datagrid,
  DateInput,
  Filter,
  List,
  SimpleList,
  TextField,
  TextInput,
} from "react-admin";
import { makeStyles, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  image: {
    width: 60,
    height: 60,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 800,
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  page: {
    display: "flex",
  },
}));

const ShopsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="店铺信息" source="keyword" alwaysOn />
      <DateInput label="开始日期" source="start_at" alwaysOn />
      <DateInput label="结束日期" source="end_at" alwaysOn />
    </Filter>
  );
};

export const ShopQrcodeList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <List title="店铺专属二维码统计" {...props} filters={<ShopsFilter />}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <span>
              店铺名称：
              <span className={classes.ugTitle}>{record.shop_name.zh}</span>
            </span>
          )}
          secondaryText={(record) => <span>会员名称 {record.member_name}</span>}
        />
      ) : (
        <Datagrid>
          <TextField source="shop_name.zh" label="店铺中文名称" />
          <TextField
            source="shop_name.ug"
            label="店铺维文名称"
            className={classes.ugTitle}
          />
          <TextField source="member_name" label="会员名称" />
          <TextField source="mobile" label="会员手机号" />
          <TextField
            source="floor_name.zh"
            label="楼层"
            className={classes.ugTitle}
          />
          <TextField
            source="created_at"
            label="邀请日期"
            className={classes.ugTitle}
          />
          <TextField
            source="became_member_at"
            label="注册日期"
            className={classes.ugTitle}
          />
        </Datagrid>
      )}
    </List>
  );
};
