import {
  Create,
  Datagrid,
  List,
  SimpleList,
  SimpleForm,
  ImageInput,
  ImageField,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  TextInput,
  NumberInput,
  ChipField,
  NumberField,
  ReferenceField,
  Filter,
  DateField,
  ListContextProvider,
  useListController,
  useQuery,
  Pagination,
  useRecordContext,
  DateTimeInput,
  BooleanInput,
} from "react-admin";
import {
  makeStyles,
  useMediaQuery,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { Pagination as Ppagination } from "./components";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { CreateToolbar, TopBarActions } from "./components";
import { imageInputFormat } from "./constants";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
  image: {
    width: 60,
    height: 60,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 800,
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  page: {
    display: "flex",
  },
}));

export const MemberGiftsList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <List title="会员礼品" {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <span>
              {record.name.zh}{" "}
              <span className={classes.ugTitle}>{record.name.ug}</span>
            </span>
          )}
          secondaryText={(record) => (
            <span>所需积分 {record.exchange_points}</span>
          )}
        />
      ) : (
        <Datagrid>
          <ImageField
            source="image.zh"
            label="中文图片"
            classes={{ image: classes.image }}
          />
          <ImageField
            source="image.ug"
            label="维文图片"
            classes={{ image: classes.image }}
          />
          <TextField source="name.zh" label="中文名称" />
          <TextField
            source="name.ug"
            label="维文名称"
            className={classes.ugTitle}
          />
          <TextField source="description.zh" label="中文说明" />
          <TextField
            source="description.ug"
            label="维文说明"
            className={classes.ugTitle}
          />
          <NumberField source="exchange_points" label="所需积分" />
          <NumberField source="limit_per_user" label="每人限领" />
          <NumberField source="total" label="总票数" />
          <NumberField source="quantity" label="剩余票数" />
          {/* <ReferenceField
            source="coupon_id"
            reference="coupons"
            label="商场优惠券"
          >
            <ChipField source="face_price" />
          </ReferenceField>
          <ReferenceField
            source="supermarket_coupon_id"
            reference="supermarket-coupons"
            label="超市优惠券"
          >
            <ChipField source="face_price" />
          </ReferenceField> */}
          <EditButton label="编辑" />
          <DeleteButton
            mutationMode="pessimistic"
            confirmTitle=""
            label="删除"
          />
        </Datagrid>
      )}
    </List>
  );
};

export const MemberGiftsCreate = (props) => {
  const [coupon, setcoupon] = useState(null);
  const [open, setOpen] = useState(false);
  const [shopOpen, setShopOpen] = useState(false);
  const [cvalue, setCvalue] = useState("gift");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort] = useState({ field: "id", order: "ASC" });
  const classes = useStyles();
  const { total } = useQuery({
    type: "getList",
    resource: "coupons",
    payload: {
      pagination: { page, perPage },
      sort,
      filter: {},
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setCvalue(event.target.value);
  };

  const SelectButton = () => {
    const record = useRecordContext();

    return (
      <Button
        style={{ width: 50, fontSize: 5 }}
        onClick={() => {
          setOpen(false);
          setShopOpen(false);
          setcoupon(record);
          localStorage.setItem("giftCoupon", JSON.stringify(record));
        }}
        variant="contained"
        color="primary"
        disableElevation
      >
        选择
      </Button>
    );
  };

  useEffect(() => {
    setcoupon(null);
    localStorage.setItem("giftCoupon", null);
  }, [cvalue]);

  return (
    <Create {...props} title="新增礼品" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label="中文名称" required />
        <TextInput source="name.ug" label="维文名称" required />
        <TextInput
          source="description.zh"
          label="中文说明"
          multiline
          fullWidth
          rows={6}
          required
        />
        <TextInput
          source="description.ug"
          label="维文说明"
          multiline
          fullWidth
          rows={6}
          style={{ textAlign: "right", direction: "rtl" }}
          required
        />
        <DateTimeInput source="ended_at" label="有效期限" required />
        <NumberInput source="exchange_points" label="所需积分" required />
        <NumberInput source="limit_per_user" label="每人限领" required />
        <NumberInput source="quantity" label="优惠券总数" required />
        <BooleanInput
          label="状态"
          source="status"
          format={(r) => r === 1}
          parse={(i) => (i === true ? 1 : 0)}
          defaultValue={1}
        />
        <ImageInput
          required
          source="image.zh"
          label="中文图片"
          accept="image/*"
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          required
          source="image.ug"
          label="维文图片"
          accept="image/*"
        >
          <ImageField source="src" />
        </ImageInput>
        <div>
          <FormControl component="fieldset" style={{ display: "flex" }}>
            <FormLabel component="legend">优惠券类型选择</FormLabel>
            <RadioGroup
              aria-label="优惠券类型选择"
              name="couponType"
              value={cvalue}
              onChange={handleChange}
            >
              <FormControlLabel value="gift" control={<Radio />} label="默认" />
              <FormControlLabel
                disabled
                value="shop"
                control={<Radio />}
                label="商铺优惠券"
              />
              <FormControlLabel
                disabled
                value="supermarket"
                control={<Radio />}
                label="超市优惠券"
              />
            </RadioGroup>
          </FormControl>
          {cvalue === "shop" ? (
            <Button color="primary" onClick={() => setShopOpen(true)}>
              选择商铺优惠券
            </Button>
          ) : null}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={shopOpen}
            onClose={() => setShopOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={shopOpen}>
              <div className={classes.paper}>
                <ListContextProvider
                  label="商场优惠券"
                  title="商场优惠券"
                  value={useListController({
                    basePath: props.match?.path,
                    resource: "coupons",
                    filter: {},
                  })}
                >
                  <Datagrid>
                    <SelectButton />
                    <TextField source="bar_price" label="满" />
                    <TextField source="face_price" label="减" />
                    <TextField source="exchange_points" label="所需积分" />
                  </Datagrid>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                  />
                </ListContextProvider>
              </div>
            </Fade>
          </Modal>
        </div>
        <div>
          {cvalue === "supermarket" ? (
            <Button color="primary" onClick={handleOpen}>
              选择超市优惠券
            </Button>
          ) : null}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <ListContextProvider
                  label=" 超市优惠券"
                  title="超市优惠券"
                  {...props}
                  value={useListController({
                    basePath: props.match?.path,
                    resource: "supermarket-coupons",
                    filter: {},
                  })}
                >
                  <Datagrid>
                    <SelectButton />
                    <TextField source="bar_price" label="满" />
                    <TextField source="face_price" label="减" />
                    <TextField source="exchange_points" label="所需积分" />
                  </Datagrid>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                  />
                </ListContextProvider>
              </div>
            </Fade>
          </Modal>
          {cvalue !== "gift" && coupon ? (
            <div>
              满 {coupon?.bar_price} 减 {coupon?.face_price}
            </div>
          ) : null}
        </div>
      </SimpleForm>
    </Create>
  );
};

export const MemberGiftsEdit = (props) => {
  return (
    <Edit {...props} title="编辑礼品" actions={<TopBarActions />}>
      <SimpleForm>
        <TextInput source="name.zh" label="中文名称" />
        <TextInput source="name.ug" label="维文名称" />
        <TextInput
          source="description.zh"
          label="中文说明"
          multiline
          fullWidth
          rows={6}
        />
        <TextInput
          source="description.ug"
          label="维文说明"
          multiline
          fullWidth
          rows={6}
          style={{ textAlign: "right", direction: "rtl" }}
        />
        <DateTimeInput source="ended_at" label="有效期限" required />
        <NumberInput source="exchange_points" label="所需积分" required />
        <NumberInput source="limit_per_user" label="每人限领" required />
        <NumberInput source="quantity" label="优惠券总数" required />
        <BooleanInput
          label="状态"
          source="status"
          format={(r) => r === 1}
          parse={(i) => (i === true ? 1 : 0)}
          defaultValue={1}
        />
        <ImageInput
          source="image.zh"
          label="中文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          source="image.ug"
          label="维文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

const OrderFilter = (props) => (
  <Filter {...props}>
    <TextInput label="搜索" source="mobile" alwaysOn />
  </Filter>
);

export const MemberGiftOrdersList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      title="礼品订单"
      {...props}
      pagination={<Ppagination />}
      filters={<OrderFilter />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.order_no}
          secondaryText={(record) => (
            <span>兑换时间 {record.exchanged_at}</span>
          )}
          tertiaryText={(record) => <span>核销时间 {record.audited_at}</span>}
        />
      ) : (
        <Datagrid>
          <TextField label="订单编号" source="order_no" />
          <ReferenceField
            label="会员姓名"
            source="member_id"
            reference="members"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="会员电话"
            source="member_id"
            reference="members"
          >
            <ChipField source="mobile" />
          </ReferenceField>

          <ReferenceField
            label="礼品"
            source="member_gift_id"
            reference="member/gifts"
          >
            <ChipField source="name.zh" />
          </ReferenceField>
          <NumberField label="消耗积分" source="exchanged_points" />
          <DateField
            label="兑换时间"
            source="exchanged_at"
            showTime
            locales="zh-CN"
          />
          <TextField label="核销人" source="auditor.data.name" />
          <DateField
            source="audited_at"
            showTime
            label="核销时间"
            locales="zh-CN"
          />
        </Datagrid>
      )}
    </List>
  );
};
