import {
  Create,
  Datagrid,
  List,
  SimpleList,
  SimpleForm,
  ImageInput,
  ImageField,
  EditButton,
  DeleteButton,
  Edit,
  TextInput,
  TextField,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { useMediaQuery } from "@material-ui/core";
import { TopBarActions, CreateToolbar } from "./components";
import { imageInputFormat } from "./constants";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image"],
  ["clean"],
];

export const ArticleList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List title="文章" {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.title.zh}
          secondaryText={(record) => record.title.ug}
        />
      ) : (
        <Datagrid>
          <TextField source="title.zh" label="文章中文名称" />
          <TextField source="title.ug" label="文章维文名称" />
          <ImageField source="image.zh" label="中文图片" />
          <ImageField source="image.ug" label="维文图片" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export const ArticleEdit = (props) => {
  return (
    <Edit {...props} title="编辑文章" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="title.zh" label="文章中文名称" />
        <TextInput source="title.ug" label="文章维文名称" />
        <RichTextInput
          source="detail.zh"
          label="中文内容"
          toolbar={toolbarOptions}
        />
        <RichTextInput
          source="detail.ug"
          label="维文内容"
          toolbar={toolbarOptions}
        />
        <ImageInput
          source="image.zh"
          label="中文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          source="image.ug"
          label="维文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const ArticleCreate = (props) => {
  return (
    <Create {...props} title="新增文章" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="title.zh" label="文章中文名称" />
        <TextInput source="title.ug" label="文章维文名称" />
        <RichTextInput
          source="detail.zh"
          label="中文内容"
          toolbar={toolbarOptions}
        />
        <RichTextInput
          source="detail.ug"
          label="维文内容"
          toolbar={toolbarOptions}
        />
        <ImageInput source="image.zh" label="中文图片" accept="image/*">
          <ImageField source="src" />
        </ImageInput>
        <ImageInput source="image.ug" label="维文图片" accept="image/*">
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
