import { useMediaQuery, makeStyles } from "@material-ui/core";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  NumberField,
  SimpleList,
  TextField,
} from "ra-ui-materialui";
import { Create, Edit, NumberInput, SimpleForm, TextInput } from "react-admin";
import { CreateToolbar, TopBarActions } from "./components";
import { currencyProps } from "./constants";

const useStyles = makeStyles(() => ({
  ugTitle: {
    fontFamily: "UgFont",
  },
}));

export const CategoriesList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <List {...props} title="店铺分类">
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <span>
              名称 {record.name.zh}
              <span className={classes.ugTitle}> {record.name.ug}</span>
            </span>
          )}
          tertiaryText={(record) =>
            `积分规则（元/积分） ${record.exchange_rate}`
          }
        />
      ) : (
        <Datagrid>
          <TextField source="name.zh" label="中文名称" />
          <TextField
            source="name.ug"
            label="维文名称"
            className={classes.ugTitle}
          />
          <NumberField
            source="exchange_rate"
            label="积分规则（元/积分）"
            {...currencyProps}
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const CatTitle = ({ record }) => {
  return <span>分类名 {record ? `"${record.name.zh}"` : ""}</span>;
};

export const CategoriesEdit = (props) => {
  return (
    <Edit {...props} title={<CatTitle />} actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label="中文名称" />
        <TextInput source="name.ug" label="维文名称" />
        <NumberInput source="exchange_rate" label="积分规则（元/积分）" />
      </SimpleForm>
    </Edit>
  );
};

export const CategoriesCreate = (props) => {
  return (
    <Create {...props} actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label="中文名称" />
        <TextInput source="name.ug" label="维文名称" />
        <NumberInput source="exchange_rate" label="积分规则（元/积分）" />
      </SimpleForm>
    </Create>
  );
};
