import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  SimpleList,
  TextField,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { FullNameField } from "./components";

const BirthdayFilter = (props) => {
  return (
    <Filter {...props}>
      <DateInput alwaysOn label="生日日期" source="birthday" />
    </Filter>
  );
};

export const MemberBirthdayList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const strDate = date.getDate();
  const dfbir = `${year}-${month}-${strDate}`;

  return (
    <>
      <List
        {...props}
        title="会员今日生日"
        filters={<BirthdayFilter />}
        filterDefaultValues={{ birthday: dfbir }}
      >
        {isSmall ? (
          <SimpleList
            primaryText={() => "姓名及生日"}
            secondaryText={(record) => `${record.name} ${record.birthday}`}
          />
        ) : (
          <Datagrid optimized>
            <FullNameField />
            <TextField source="name" label="姓名" />
            <TextField source="mobile" label="手机号" />
            <DateField source="birthday" label="生日" />
            <TextField source="level.data.name.zh" label="会员等级" />
            <TextField source="points" label="积分" />
          </Datagrid>
        )}
      </List>
    </>
  );
};
